#nav-icon1{
    width: 30px;
    height: 20px;
    position: relative;
    margin: 50px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  
  #nav-icon1 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #3aafd6;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  #nav-icon1 span:nth-child(1) {
    top: 0px;
  }
  
  #nav-icon1 span:nth-child(2) {
    top: 8px;
  }
  
  #nav-icon1 span:nth-child(3) {
    top: 16px;
  }
  
  #nav-icon1.open span:nth-child(1) {
    top: 8px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  
  #nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -40px;
  }
  
  #nav-icon1.open span:nth-child(3) {
    top: 8px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  /* .additional-content {
    padding: 10px;
    overflow: hidden;
    background-color: '#1e2732'; 
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: padding 4s ease-in-out;
  } */
  .additional-content {
    max-height: 200px;
    padding-bottom: 20px;
    overflow: hidden;
    background-color: #1e2732;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: max-height 1s ease-in-out;
    animation: slideIn 1s ease-in-out forwards;
  }
  
  .additional-content.open {
    max-height: 200px; /* Adjust the height as needed */
    transition: max-height 1s ease-in-out;
    animation: slideOut 1s ease-in-out forwards;
  }
  
  @keyframes slideIn {
    from {
      max-height: 0;
    }
    to {
      max-height: 200px; /* Adjust the height as needed */
    }
  }
  @keyframes slideOut{
    from{
        max-height: 0;
    }
    to{
        max-height: 200px;
    }
  }
  
 
  